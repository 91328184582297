import {
  Box,
  ClickAwayListener,
  Drawer,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NendaPortalLogo from "../assets/images/svg/nenda_portal_logo.svg";
import NendaSmallLogo from "../assets/images/svg/nenda_symbol.svg";
import { CustomerPortalState } from "../store";
import { drawerWidth } from "../store/constant";
import { selectUserRole } from "../store/reducers/authReducer";
import {
  CustomizationState,
  ToggleMenu,
} from "../store/reducers/customizationReducer";
import {
  selectCurrentNavigationLevel,
  selectNavigationScope,
} from "../store/reducers/workspaceReducer";
import MenuList from "./MenuList";
import ToggleSidebarButton from "./MenuList/components/ToggleSidebarButton";
import { dynamicMenu } from "./menuItems";
import {
  getPremisesByCompanyId,
  selectNavigatedPremise,
} from "../store/reducers/organizationUnitReducer";
import { getCompanies } from "../store/reducers/companyReducer";

type sidebarProps = {
  drawerOpen: boolean;
};
const SideBarDrawer = ({ menuItems, drawerOpen }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customization: CustomizationState = useSelector<
    CustomerPortalState,
    CustomizationState
  >((state) => state.customization);

  const handleToggleSidebar = () => {
    dispatch(ToggleMenu());
  };

  const navigateHome = () => {
    navigate("/");

    if (isMobile) {
      handleToggleSidebar();
    }
  };

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (drawerOpen && isMobile) {
      const target = event.target as HTMLElement;

      if (document.getElementById("toggle-sidebar")?.contains(target)) {
        return; 
      }

      handleToggleSidebar();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickOutside}>
      <Stack
        sx={{
          height: "100%",
          overflowX: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginRight: "1rem",
            marginLeft: "1rem",
            justifyContent: customization.opened ? "flex-end" : "center",
          }}
        >
          <ToggleSidebarButton onClick={handleToggleSidebar} />
        </Box>

        <Box
          sx={{
            [theme.breakpoints.down("md")]: {
              display: drawerOpen ? "box" : "none",
            },
          }}
        >
          <Box
            onClick={navigateHome}
            sx={{
              width: "100%",
              height: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            {customization.opened ? (
              <NendaPortalLogo />
            ) : (
              <NendaSmallLogo style={{ fill: theme.palette.primary.light }} />
            )}
          </Box>
          <MenuList menuItems={menuItems} />
        </Box>
      </Stack>
    </ClickAwayListener>
  );
};

const Sidebar = ({ drawerOpen }: sidebarProps) => {
  const theme = useTheme();
  const scope = useSelector(selectNavigationScope);
  const navigationLevel = useSelector(selectCurrentNavigationLevel);
  const role = useSelector(selectUserRole);
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const companies = useSelector(getCompanies);
  const premise = useSelector(selectNavigatedPremise);
  const company = companies.find((c) => c._id === scope.company);
  const scopedPremises = useSelector((state: CustomerPortalState) =>
    getPremisesByCompanyId(state, scope.company || "")
  );

  const menu = useCallback(() => {
    return dynamicMenu({
      scope: {
        premise: premise,
        scopedPremises,
        company,
      },
      role,
      navigationLevel,
    });
  }, [premise, scopedPremises, company, role, navigationLevel]);

  const menuItems = { items: menu() };

  return menuItems.items?.length ? (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? drawerWidth : "auto",
        position: "relative",
      }}
    >
      <Drawer
        variant={"permanent"}
        anchor="left"
        open={drawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerOpen ? "80%" : 0,
            background: drawerOpen
              ? theme.palette.gradient.darkBlue
              : "transparent",
            [theme.breakpoints.up("md")]: {
              width: drawerOpen ? drawerWidth : 80,
              background: theme.palette.gradient.darkBlue,
            },
            transition: "width 0.1s ease-in-out",
            color: theme.palette.text.primary,
            borderRight: "none",
            pt: "16px",
          },
        }}
        ModalProps={{ keepMounted: true }}
      >
        <SideBarDrawer menuItems={menuItems} drawerOpen={drawerOpen} />
      </Drawer>
    </Box>
  ) : null;
};

export default Sidebar;
